import React, { useMemo, useState } from 'react'
import { useValidatedField } from '@/hooks/useValidatedField'
import { error, log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import dynamic from 'next/dynamic'

const Heading2 = dynamic(import('@csc/dls/Heading2'))
const Row = dynamic(import('@csc/dls/Row'))
const Link = dynamic(import('@csc/dls/Link'))
const Text = dynamic(import('@csc/dls/Text'))

const PrimaryButton = dynamic(import('@csc/dls/PrimaryButton'))
const AlphanumericInput = dynamic(import('@csc/dls/AlphanumericInput'))

const LoginWithExistingAccount: React.FC<{
  onSuccess: () => void
  successMessage: string
  headingMessage: string
  secondMessage: string
  reloadCustomer?: () => void
}> = ({
  onSuccess,
  successMessage,
  headingMessage,
  secondMessage,
  reloadCustomer = () => { },
}) => {
  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false)
  const [submitFailed, setSubmitFailed] = useState<boolean>(false)
  const [isSubmitInProgress, setIsSubmitInProgress] = useState<boolean>(false)
  const [loginSucceeded, setLoginSucceeded] = useState<boolean>(false)
  const required = (value: string): string => {
    if (!submitAttempted) {
      return ''
    }
    return `${value || ''}`.trim() ? '' : 'Required'
  }

  // eslint-disable-next-line no-control-regex
  const emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
  const validEmail = (email: string) => {
    if (!submitAttempted) {
      return ''
    }
    if (!emailPattern.test(email)) {
      return 'Please enter a valid Email'
    }
    return ''
  }

  const showFeedBack = () => submitAttempted

  const password = useValidatedField('', required, showFeedBack)
  const email = useValidatedField('', validEmail, showFeedBack)

  const onSubmit = async () => {
    if (isSubmitInProgress) {
      return
    }
    setSubmitAttempted(true)
    setSubmitFailed(false)
    try {
      const [
        { default: Account, migrateFromLegacy },
        { KustomerLogIn },
      ] = await allPromisesWithRetries(() => [
        import('@/services/Account'),
        import('../ScriptIntegrations/KustomerChatIntegration'),
      ])
      if (!email.issues && !password.issues) {
        setIsSubmitInProgress(true)
        const user = {
          email: `${email?.value || ''}`.trim(),
          password: password?.value,
        }
        await migrateFromLegacy({
          identifier: email?.value,
          password: password?.value,
        })
        const { success } = await Account.signUserIn(user)
        log(`Account.signUserIn success: ${String(success)}`)
        if (success) {
          // eslint-disable-next-line no-restricted-globals
          setLoginSucceeded(true)
          try {
            await KustomerLogIn()
          } catch (err) {
            error(`Failed to perform Kustomer Login ${String(err)}`)
          }
          log('components/Login/LoginWithExistingAccount.tsx calling onSuccess()')
          onSuccess()
        } else {
          setSubmitFailed(true)
        }
      }
    } catch (err) {
      error(`Login Failed ${(String(err))}`)
      setSubmitFailed(true)
    } finally {
      setIsSubmitInProgress(false)
      reloadCustomer()
    }
  }

  const withFormChange = (
    ({ setValue }: { setValue: (v: string) => void }) => (e: { target: { value: string } }) => {
      setSubmitAttempted(false)
      setValue(e.target.value)
    }
  )
  const emailErrorMessage = useMemo(() => {
    if (email.issues) {
      return email.issues
    }
    return (submitFailed && submitAttempted) ? 'Invalid email and / or password' : undefined
  }, [email.issues, submitAttempted, submitFailed])

  return (
    <Row cols={1} gapY="lg">
      <Heading2 textTag="p">{headingMessage}</Heading2>
      <Text className="h-10">{secondMessage}</Text>
      <form
        name="user login form"
        autoComplete="on"
        action=""
        noValidate
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <Row cols={1} gap="md">
          <AlphanumericInput
            id="login_email"
            name="email"
            type="email"
            placeholder="Email"
            autoComplete="email"
            label="Email"
            inputRef={email.ref}
            value={email.value}
            onChange={withFormChange(email)}
            errorMessage={emailErrorMessage}
            data-testid="login_email"
          />
          <AlphanumericInput
            id="login_password"
            autoComplete="current-password"
            name="password"
            type="password"
            placeholder="Password"
            label="Password"
            inputRef={password.ref}
            value={password.value}
            onChange={withFormChange(password)}
            errorMessage={password.issues ? password.issues : undefined}
            data-testid="login_password"
          />
          <Link className="no-underline" href="/forgot-password" target="_blank">
            <Text size="sm" className="cursor-pointer" data-testid="forgot_password_link">
              Forgot your password?
            </Text>
          </Link>
          <PrimaryButton
            htmlType="button"
            data-testid="Login_Submit_CTA"
            id="Submit_Login_Button"
            onClick={onSubmit}
            className="relative"
          >
            {isSubmitInProgress
              ? '... Signing In'
              : 'Sign in'}
          </PrimaryButton>
          {loginSucceeded && (
            <Text>
              {successMessage}
            </Text>
          )}
        </Row>
      </form>
    </Row>
  )
}

export default LoginWithExistingAccount
